import { ReactNode, FC, Fragment, StrictMode, useEffect } from 'react';
import { Store } from 'redux';
import { parse } from 'query-string';
import { Route } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { LoadableComponent } from '@loadable/component';
import { Routes, RouteProps, useLocation } from 'react-router';

import {
    Brand,
    AppPrompt,
    isAdminPortal,
    brand,
} from '@eon-home/react-library';

import { HvacDeviceModel, HvacDeviceModelTypeEnum } from '@swagger-http';

import * as Loadables from './loadables';
import { initialState } from '@store';
import { Head, Toast, Tour, DataConsent } from '@components';
import { analytics, AnalyticsEvent } from '@analytics';
import { Scope, Routes as AppRoutes } from '@tools/enums';
import { getLocale, disconnectLiveData } from '@store/actions';
import {
    DEBUG,
    IS_PROD,
    TARGET_ROUTE,
    smartDevicesReadScopes,
} from '@tools/constants';
import {
    useHasGcp,
    useLoggedIn,
    useAppLoading,
    useAppSelector,
    useHasInverter,
    useHasEmobility,
    useHasUkSmartMeter,
} from '@store/selectors';
import {
    checkForScopes,
    isSafariBrowser,
    composeClassName,
    useLocationChange,
    useInternalRouting,
    shouldShowConsentRestrictions,
} from '@tools/utils';

import './index.scss';

declare module 'react' {
    interface CSSProperties {
        fill?: string;
    }
}

declare global {
    interface Window {
        store: Store<typeof initialState>;
        Cypress: Record<string, any>;
        dataLayer: AnalyticsEvent[];
        EventSourcePolyfill: typeof EventSource;
        __BRAND__SPECIFIC__DATA__: Brand;
    }

    namespace NodeJS {
        interface ProcessEnv {
            BRANCH: 'develop' | 'staging' | 'master';
            NODE_ENV: 'development' | 'production';
            API_URL_DEFAULT: string;
            SSE_URL_DEFAULT: string;
            CMS_CONTENT_TYPE: 'draft' | 'published';
            WEBSOCKET_URL_DEFAULT: string;
        }
    }
}

type PrivateRouteProps = RouteProps & {
    requiredScopes?: Scope[];
};

interface RouteData {
    path: AppRoutes;
    scopes: Scope[];
    isPublic: boolean;
}

export const renderPrivateRoute = (
    loggedIn: boolean,
    props: PrivateRouteProps,
) => {
    const allowAccess =
        props.requiredScopes && props.requiredScopes.length
            ? checkForScopes(props.requiredScopes)
            : true;

    return loggedIn && allowAccess ? <Route {...props} /> : null;
};

const renderRoutes = (
    data: RouteData[],
    Component: LoadableComponent<any>,
    setActive: (path: AppRoutes) => AppRoutes,
    isLoggedIn: boolean,
): ReactNode[] | null =>
    data.map(({ path, scopes, isPublic }) => {
        const props = {
            key: uuidv4(),
            path,
            element: <Component active={setActive(path)} />,
            requiredScopes: scopes,
        };

        return isPublic ? (
            <Route {...props} />
        ) : (
            renderPrivateRoute(isLoggedIn, props)
        );
    });

interface ApplicationProps {
    hasGCP: boolean;
    isLoggedIn: boolean;
    hasInverter: boolean;
    hasEmobility: boolean;
    hasUkSmartMeter: boolean;
    hasHeatingDevices: boolean;
}

export const Application: FC<Readonly<ApplicationProps>> = ({
    hasGCP,
    isLoggedIn,
    hasInverter,
    hasEmobility,
    hasUkSmartMeter,
    hasHeatingDevices,
}: ApplicationProps) => (
    <div className={composeClassName('app', [isLoggedIn ? '' : 'public'])}>
        <Head />
        <Toast />

        {isLoggedIn && (
            <>
                <Loadables.Messaging />
                <Loadables.CookieConsent />
                <Loadables.SessionManager />
            </>
        )}

        <Loadables.UpdateAvailable />
        <Loadables.DataStore isLoggedIn={isLoggedIn} />
        <Loadables.Menu isLoggedIn={isLoggedIn} />
        <Loadables.Header isLoggedIn={isLoggedIn} />
        <Loadables.MobileHeader isLoggedIn={isLoggedIn} />

        <div className="o-container">
            {isLoggedIn && (
                <>
                    <Loadables.IncidentMessage />
                    <Loadables.EmobilityTamperNotification />
                    <Loadables.VirtaFirmwareUpdate />
                    <Loadables.QualtricsPrompt />

                    <div className="c-status-widgets">
                        <Loadables.RelinkSmartHome />
                        <Loadables.InstallationStatus />
                    </div>
                </>
            )}

            <Routes>
                {!isLoggedIn && (
                    <Route path={AppRoutes.AUTH} element={<Loadables.Auth />} />
                )}
                <Route
                    path={AppRoutes.REGISTER}
                    element={<Loadables.Registration />}
                />
                <Route
                    path={AppRoutes.MOBILE_REGISTER}
                    element={<Loadables.MobileRegistration />}
                />
                <Route
                    path={AppRoutes.STEPUP_REGISTRATION}
                    element={<Loadables.StepUpRegistration />}
                />
                <Route path={AppRoutes.OAUTH} element={<Loadables.OAuth />} />
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.BASE,
                    element: (
                        <>
                            <Loadables.Home />
                            <Loadables.HomeMobile />
                        </>
                    ),
                })}

                <Route
                    path={AppRoutes.GOODBYE}
                    element={<Loadables.AccountDeleted />}
                />
                {renderRoutes(
                    [
                        {
                            path: AppRoutes.ENERGY,
                            scopes: [
                                Scope.ENERGYDEVICES_PVB_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                            ],
                            isPublic: false,
                        },
                        {
                            path: AppRoutes.ENERGY_OVERVIEW,
                            scopes: [
                                Scope.ENERGYDEVICES_PVB_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                            ],
                            isPublic: false,
                        },
                        {
                            path: AppRoutes.ENERGY_GENERATION,
                            scopes: [Scope.ENERGYDEVICES_PVB_READ],
                            isPublic: false,
                        },
                        {
                            path: AppRoutes.ENERGY_CONSUMPTION,
                            scopes: [
                                Scope.ENERGYDEVICES_PVB_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                            ],
                            isPublic: false,
                        },
                        {
                            path: AppRoutes.ENERGY_SOLAR_CLOUD,
                            scopes: [
                                Scope.ENERGYDEVICES_PVB_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                            ],
                            isPublic: false,
                        },
                        {
                            path: AppRoutes.ENERGY_SOLAR_BALANCE,
                            scopes: [
                                Scope.ENERGYDEVICES_PVB_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                            ],
                            isPublic: false,
                        },
                    ],
                    Loadables.Energy,
                    (route: AppRoutes) =>
                        route === AppRoutes.ENERGY
                            ? hasGCP
                                ? AppRoutes.ENERGY_OVERVIEW
                                : AppRoutes.ENERGY_GENERATION
                            : route,
                    isLoggedIn,
                )}
                <Route element={<Loadables.NotFound />} />
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.SMART_METER,
                    element: <Loadables.SmartMeter />,
                    requiredScopes: [
                        Scope.ENERGYDEVICES_GAS_READ,
                        Scope.ENERGYDEVICES_GCP_READ,
                        Scope.ENERGYDEVICES_PVB_READ,
                    ],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.ENERGY_INSTALL_PV,
                    element: <Loadables.PvInstall />,
                    requiredScopes: [Scope.ENERGYDEVICES_PVB_WRITE],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.ENERGY_INSTALL_GRIDX,
                    element: <Loadables.GridXInstall />,
                    requiredScopes: [Scope.ENERGYDEVICES_GATEWAY_READ],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.EMOBILITY_CHARGING_HISTORY,
                    element: <Loadables.WallboxChargingHistory />,
                    requiredScopes: [Scope.ENERGYDEVICES_EV_READ],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.EMOBILITY_CHARGING_HISTORY,
                    element: <Loadables.ElectricCarChargingHistory />,
                    requiredScopes: [Scope.ENERGYDEVICES_ELECTRIC_CAR_READ],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.EMOBILITY,
                    element: <Loadables.Wallbox />,
                    requiredScopes: [Scope.ENERGYDEVICES_EV_READ],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.EMOBILITY,
                    element: <Loadables.ElectricCar />,
                    requiredScopes: [Scope.ENERGYDEVICES_ELECTRIC_CAR_READ],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.DEVICES,
                    element: <Loadables.Devices />,
                    requiredScopes: [...smartDevicesReadScopes],
                })}
                {renderRoutes(
                    [
                        {
                            path: AppRoutes.INSIGHTS,
                            isPublic: false,
                            scopes: [
                                Scope.ENERGYDEVICES_GAS_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                                Scope.ENERGYDEVICES_PVB_READ,
                                ...smartDevicesReadScopes,
                            ],
                        },
                        {
                            path: AppRoutes.INSIGHTS_PV,
                            isPublic: false,
                            scopes: [
                                Scope.ENERGYDEVICES_GAS_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                                Scope.ENERGYDEVICES_PVB_READ,
                                ...smartDevicesReadScopes,
                            ],
                        },
                        {
                            path: AppRoutes.INSIGHTS_SMART_METER,
                            isPublic: false,
                            scopes: [
                                Scope.ENERGYDEVICES_GAS_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                                Scope.ENERGYDEVICES_PVB_READ,
                                ...smartDevicesReadScopes,
                            ],
                        },
                        {
                            path: AppRoutes.INSIGHTS_HEATING,
                            isPublic: false,
                            scopes: [
                                Scope.ENERGYDEVICES_GAS_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                                Scope.ENERGYDEVICES_PVB_READ,
                                ...smartDevicesReadScopes,
                            ],
                        },
                        {
                            path: AppRoutes.INSIGHTS_EMOBILITY,
                            isPublic: false,
                            scopes: [
                                Scope.TARIFF_READ,
                                Scope.ENERGYDEVICES_GAS_READ,
                                Scope.ENERGYDEVICES_GCP_READ,
                                Scope.ENERGYDEVICES_PVB_READ,
                                ...smartDevicesReadScopes,
                            ],
                        },
                    ],
                    Loadables.Insights,
                    (route: AppRoutes) => {
                        const defaultRoute = hasInverter
                            ? AppRoutes.INSIGHTS_PV
                            : hasUkSmartMeter
                            ? AppRoutes.INSIGHTS_SMART_METER
                            : hasHeatingDevices
                            ? AppRoutes.INSIGHTS_HEATING
                            : hasEmobility
                            ? AppRoutes.INSIGHTS_EMOBILITY
                            : route;

                        return route === AppRoutes.INSIGHTS
                            ? defaultRoute
                            : route;
                    },
                    isLoggedIn,
                )}
                {renderRoutes(
                    [
                        {
                            path: AppRoutes.MORE,
                            isPublic: true,
                            scopes: [Scope.ME_READ],
                        },
                        ...(isLoggedIn
                            ? [
                                  {
                                      path: AppRoutes.MORE_ACCOUNT,
                                      isPublic: false,
                                      scopes: [Scope.ME_READ],
                                  },
                                  {
                                      path: AppRoutes.MORE_PRODUCTS,
                                      isPublic: false,
                                      scopes: [Scope.SMARTHOME_PAIRING_READ],
                                  },
                                  {
                                      path: AppRoutes.MORE_EMOBILITY_SETTINGS,
                                      isPublic: false,
                                      scopes: [
                                          Scope.ENERGYDEVICES_EV_READ,
                                          Scope.ENERGYDEVICES_ELECTRIC_CAR_READ,
                                      ],
                                  },
                                  {
                                      path: AppRoutes.MORE_TARIFF_RATE_DETAILS,
                                      isPublic: false,
                                      scopes: [Scope.TARIFF_READ],
                                  },
                                  {
                                      path: AppRoutes.MORE_TARIFF_RATE_HISTORY,
                                      isPublic: false,
                                      scopes: [Scope.TARIFF_READ],
                                  },
                                  {
                                      path: AppRoutes.MORE_DATA_DOWNLOAD,
                                      isPublic: false,
                                      scopes: [Scope.ME_READ],
                                  },
                                  {
                                      path: AppRoutes.MORE_HOME_PROFILE,
                                      isPublic: false,
                                      scopes: [Scope.ME_READ, Scope.ME_WRITE],
                                  },
                                  {
                                      path: AppRoutes.MORE_ARCHIVE,
                                      isPublic: false,
                                      scopes: [Scope.ME_READ, Scope.ME_WRITE],
                                  },
                              ]
                            : []),
                        {
                            path: AppRoutes.MORE_TERMS,
                            isPublic: true,
                            scopes: [],
                        },
                        {
                            path: AppRoutes.MORE_PRIVACY,
                            isPublic: true,
                            scopes: [],
                        },
                        {
                            path: AppRoutes.MORE_FAQ,
                            isPublic: true,
                            scopes: [],
                        },
                        {
                            path: AppRoutes.MORE_CONTACT,
                            isPublic: true,
                            scopes: [],
                        },
                        {
                            path: AppRoutes.MORE_CONSENT,
                            isPublic: true,
                            scopes: [],
                        },
                        {
                            path: AppRoutes.MORE_ACKNOWLEDGEMENT,
                            isPublic: true,
                            scopes: [],
                        },
                        ...(isLoggedIn
                            ? [
                                  {
                                      path: AppRoutes.MORE_REFERRAL_TERMS,
                                      isPublic: false,
                                      scopes: [],
                                  },
                              ]
                            : []),
                    ],
                    Loadables.More,
                    // prettier-ignore
                    (route: AppRoutes) => route !== AppRoutes.MORE ? route : isLoggedIn ? AppRoutes.MORE_ACCOUNT : AppRoutes.MORE_TERMS,
                    isLoggedIn,
                )}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.CONNECT_WALLBOX,
                    element: <Loadables.ConnectWallbox />,
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.CONNECT_SUCCESS,
                    element: <Loadables.ConnectSmartDevices />,
                    requiredScopes: [Scope.SMARTHOME_PAIRING_WRITE],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.CONNECT_SMART_LIGHTS,
                    element: <Loadables.ConnectSmartDevices />,
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.CONNECT_HEATING_COOLING,
                    element: <Loadables.ConnectSmartDevices />,
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.CONNECT_ELECTRIC_CAR,
                    element: <Loadables.ConnectElectricCar />,
                    requiredScopes: [Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.CONNECT_ELECTRIC_CAR_SUCCESS,
                    element: <Loadables.ConnectElectricCar />,
                    requiredScopes: [Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE],
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: AppRoutes.CONNECT_SMART_PLUGS,
                    element: <Loadables.ConnectSmartDevices />,
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: `${AppRoutes.ACTION_REQUIRED}/:action`,
                    element: <Loadables.ActionRequired />,
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: `${AppRoutes.SURVEY}/:survey`,
                    element: <Loadables.Survey />,
                })}
                {renderPrivateRoute(isLoggedIn, {
                    path: `${AppRoutes.SALES}`,
                    element: <Loadables.Sales />,
                })}
                {/* <PrivateRoute
                    path={AppRoutes.CONNECT_SMART_METER}
                    element={<Loadables.ConnectSmartMeter />}
                    requiredScopes={[Scope.ENERGYDEVICES_PVB_WRITE]}
                /> */}
                <Route
                    path={AppRoutes.NOT_FOUND}
                    element={<Loadables.NotFound />}
                />
            </Routes>

            {isLoggedIn && <Loadables.ActivationPopup />}

            {isLoggedIn && !isAdminPortal() && isMobileOnly && <Tour />}
        </div>

        {isLoggedIn && !isAdminPortal() && !isMobileOnly && <Tour />}
    </div>
);

export const App = () => {
    const goTo = useInternalRouting();
    const { t } = useTranslation();
    const hasGCP = useHasGcp();
    const isLoggedIn = useLoggedIn();
    const appLoading = useAppLoading();
    const hasInverter = useHasInverter();
    const hasEmobility = useHasEmobility();
    const hasUkSmartMeter = useHasUkSmartMeter();
    const hasHeatingDevices = useAppSelector(
        (state) =>
            state.smartHome.devices.filter(
                ({ type }: HvacDeviceModel) =>
                    type === HvacDeviceModelTypeEnum.Heating ||
                    type === HvacDeviceModelTypeEnum.AirConditioning,
            ).length > 0,
    );

    const { pathname } = useLocation() as { pathname: AppRoutes };
    const Wrapper = IS_PROD || !isLoggedIn ? Fragment : StrictMode;

    useLocationChange((path: string) => analytics.viewPage(path));

    useEffect(() => {
        window.addEventListener('onbeforeunload', disconnectLiveData);

        // Adding a `?debug=true` to the URL will enable the debug mode
        // This mode will print useful information about missing permissions
        // in case the scopes array is empty.
        const { debug } = parse(location.search);

        if (debug === 'true') {
            localStorage.setItem(DEBUG, debug);
        }

        return () => {
            localStorage.removeItem(DEBUG);

            window.removeEventListener('onbeforeunload', disconnectLiveData);
        };
    }, []);

    useEffect(() => {
        const htmlTag = document.documentElement;
        const brandName = brand.brand
            .replace(/\./g, '')
            .replace(/ /g, '-')
            .toLowerCase();

        htmlTag.setAttribute('lang', getLocale());

        if (navigator.platform.toLowerCase().indexOf('win') > -1) {
            htmlTag.classList.add('is--windows');
        }

        if (isSafariBrowser()) {
            htmlTag.classList.add('is--safari');
        }

        htmlTag.classList.add(`theme-${brandName}`);
    }, []);

    useEffect(() => {
        const targetRoute = localStorage.getItem(TARGET_ROUTE);

        if (isLoggedIn && targetRoute !== null) {
            localStorage.removeItem(TARGET_ROUTE);

            goTo(targetRoute);
        }
    }, [goTo, isLoggedIn]);

    return (
        <Wrapper>
            <Application
                hasGCP={hasGCP}
                isLoggedIn={isLoggedIn}
                hasInverter={hasInverter}
                hasEmobility={hasEmobility}
                hasUkSmartMeter={hasUkSmartMeter}
                hasHeatingDevices={hasHeatingDevices}
            />

            {isLoggedIn && !appLoading && shouldShowConsentRestrictions() && (
                <DataConsent />
            )}
            {appLoading || [AppRoutes.REGISTER].includes(pathname) ? null : (
                <AppPrompt
                    title={t<string>('{{APPNAME}} has a mobile app')}
                    appButton={t<string>('Switch to the app')}
                    browserButton={t<string>('Continue in browser')}
                />
            )}
        </Wrapper>
    );
};

export default App;
