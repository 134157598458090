import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DefaultTFuncReturn } from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
    useLayoutEffect,
    MutableRefObject,
} from 'react';

import { Locale, UnknownFunction } from '@eon-home/react-library';

import {
    IotPairingModel,
    SaleTemplateStepModel,
    SaleTemplateStepEditModelStatusEnum,
    UserDataConsentModel,
} from '@swagger-http';

import { Nullable, RangeUnit } from '@tools/types';
import { calculatePvRatedCapacity } from './helpers';
import { Routes, GraphTypes, ConnectMode, Scope } from '@tools/enums';
import {
    Moment,
    isGBUser,
    handleError,
    checkForScopes,
    shouldShowForecast,
    cleanUpLocalStorage,
} from '.';
import {
    SmartHomeAction,
    UserActionTypes,
    LOGIN_ERROR_CODES,
    GenericActionTypes,
    SettingsActionTypes,
    HistoricalResolution,
    AggregatedDataActionTypes,
    EmobilityVendors,
} from '@store/enums';
import {
    getGraphToggles,
    prepareInitialDate,
    isTodayResWithSmartMeter,
    shouldRenderForSmartMeter,
} from './graphs';
import {
    getLocale,
    patchSale,
    getProviders,
    setSliderValue,
    setSliderTouched,
    getAggregatedData,
    getPairingDataPerProvider,
    setConsentData,
} from '@store/actions';
import {
    useSales,
    useHasGcp,
    useHasBattery,
    useAppSelector,
    useHasGasMeter,
    useHasInverter,
    useSiteTimezone,
    useHasSmartMeter,
    useHasSolarCloud,
    useEnergyProvider,
    useHasUkSmartMeter,
    useRegistrationDate,
    useHasElectricityMeter,
    useHasWallbox,
    useHasElectricCar,
} from '@store/selectors';

export const useLocationChange = (onChange: (path: string) => void) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const hasSmartMeter = useHasSmartMeter();
    const hasUkSmartMeter = useHasUkSmartMeter();

    const daysToSubtract = useMemo(() => {
        if (!hasSmartMeter && !hasUkSmartMeter) {
            return 0;
        }

        return [Routes.ENERGY_OVERVIEW, Routes.ENERGY_CONSUMPTION].includes(
            pathname as Routes,
        )
            ? 1
            : 0;
    }, [pathname, hasSmartMeter, hasUkSmartMeter]);

    // Reset graphs on each location change
    dispatch({
        type: AggregatedDataActionTypes.SET_RESOLUTION,
        payload: HistoricalResolution.DAY1HOUR,
    });

    dispatch({
        type: AggregatedDataActionTypes.SET_DATE,
        payload: Moment().subtract(daysToSubtract, 'day').toDate(),
    });

    dispatch({
        type: AggregatedDataActionTypes.SET_SLIDER_TOUCHED,
        payload: { sliderTouched: false },
    });

    dispatch({
        type: AggregatedDataActionTypes.SET_SLIDER_VALUE,
        payload: { value: null },
    });

    dispatch({
        type: AggregatedDataActionTypes.SET_SLIDER_SCROLL_POSITION,
        payload: { scrollPosition: 0 },
    });

    useEffect(() => {
        onChange(pathname);
    }, [pathname, onChange]);
};

export const usePrepareLinking = (
    vendor: EmobilityVendors.VIRTA | 'ukmeter',
) => {
    const { t } = useTranslation();
    const [mode, setMode] = useState<ConnectMode>(ConnectMode.INITIAL);
    const [state, setState] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<string | DefaultTFuncReturn>('');

    const isVirta = useMemo(() => vendor === EmobilityVendors.VIRTA, [vendor]);

    const prepareLinking = useCallback(async () => {
        const providers: IotPairingModel[] | undefined = await getProviders();

        const provider: IotPairingModel | undefined = providers?.find(
            (item: IotPairingModel) => item.name === vendor,
        );

        if (!provider) {
            setMessage(
                isVirta
                    ? t<string>('Emobility is not available.')
                    : t<string>('This feature is currently not available.'),
            );
            setMode(ConnectMode.NOT_AVAILABLE);
        }

        if (provider?.paired) {
            setMessage(t<string>('You have already linked your account.'));
            setMode(ConnectMode.NOT_AVAILABLE);
        }

        if (!provider?.paired) {
            try {
                const pairingData = await getPairingDataPerProvider(vendor);

                if (pairingData && pairingData.state) {
                    setState(pairingData.state);
                }
            } catch {
                setMessage(
                    isVirta
                        ? t(
                              'If your {{ BRANDNAME }} charger is being installed please allow 45 minutes once completed and online. Otherwise you may have entered your details incorrectly, please try again.',
                          )
                        : t<string>(
                              'Something went wrong. Please try again later',
                          ),
                );
                setMode(ConnectMode.ERROR);
            }
        }

        setLoading(false);
    }, [t, vendor, isVirta]);

    useEffect(() => {
        prepareLinking();
    }, [prepareLinking]);

    useEffect(() => {
        if (mode === ConnectMode.INITIAL) {
            setLoading(true);
            prepareLinking();
        }
    }, [mode, prepareLinking]);

    return { mode, state, loading, message, setMessage, setMode };
};

export const useResetMobileGraphSlider = () => {
    const dispatch = useDispatch();

    return useCallback(() => {
        dispatch(setSliderValue(null));
        dispatch(setSliderTouched(false));
    }, [dispatch]);
};

export const useDefaultDateFormat = () => {
    const defaultDateFormatMap: Record<Locale, string> = useMemo(
        () => ({
            en: 'MMM dd, yyyy',
            de: 'MMM dd, yyyy',
            it: 'dd MMM, yyyy',
            sv: 'MMM dd, yyyy',
            pl: 'MMM dd, yyyy',
            hu: 'yyyy. MMM dd.',
            nl: 'MMM dd, yyyy',
        }),
        [],
    );

    return defaultDateFormatMap[getLocale()];
};

export const useInitialDate = () => {
    const timezone = useSiteTimezone();
    const registrationDate = useRegistrationDate();

    return prepareInitialDate(registrationDate, timezone);
};

export const useGraphState = (
    type: GraphTypes,
    res?: HistoricalResolution,
    date?: Date,
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const gcp = useAppSelector((state) => state.energy.gcp);
    const hasGCP = useHasGcp();
    const provider = useEnergyProvider();
    const timezone = useSiteTimezone();
    const hasBattery = useHasBattery();
    const hasInverter = useHasInverter();
    const hasGasMeter = useHasGasMeter();
    const hasWallbox = useHasWallbox();
    const hasElectricCar = useHasElectricCar();
    const hasSolarCloud = useHasSolarCloud();
    const hasSmartMeter = useHasSmartMeter();
    const hasUkSmartMeter = useHasUkSmartMeter();
    const registrationDate = useRegistrationDate();
    const historicalData = useAppSelector(
        (state) => state.historicalData.aggregatedData,
    );
    const hasPvEfficiency = useAppSelector(
        (state) => state.energy.hasPvEfficiency,
    );
    const solarCloudEndDate = useAppSelector(
        (state) => state.energy.solarCloudEndDate,
    );
    const solarCloudStartDate = useAppSelector(
        (state) => state.energy.solarCloudStartDate,
    );
    const hasElectricityMeter = useHasElectricityMeter();

    const selectedRes = useAppSelector(
        (state) => state.aggregatedData.selectedRes,
    );
    const selectedDate = useAppSelector(
        (state) => state.aggregatedData.selectedDate,
    );
    const aggregatedData = useAppSelector(
        (state) => state.aggregatedData[type][res || selectedRes],
    );

    const isLoading = aggregatedData.loading;
    const initialDate = useInitialDate();

    const defaultDateFormat = useDefaultDateFormat();
    const smartMeterInstalled = hasUkSmartMeter || hasSmartMeter;

    const shouldRenderUpdated = shouldRenderForSmartMeter(
        smartMeterInstalled,
        aggregatedData.data,
        res || selectedRes,
        hasUkSmartMeter,
        type,
    );

    const isTodayWithSmartMeter = isTodayResWithSmartMeter(
        smartMeterInstalled,
        res || selectedRes,
        date || selectedDate,
    );

    const energy = useMemo(
        () => ({
            gcp,
            hasGCP,
            provider,
            hasBattery,
            hasGasMeter,
            hasInverter,
            hasSmartMeter,
            hasSolarCloud,
            hasPvEfficiency,
            hasUkSmartMeter,
            solarCloudEndDate,
            hasElectricityMeter,
            solarCloudStartDate,
        }),
        [
            gcp,
            hasGCP,
            provider,
            hasBattery,
            hasGasMeter,
            hasInverter,
            hasSmartMeter,
            hasSolarCloud,
            hasPvEfficiency,
            hasUkSmartMeter,
            solarCloudEndDate,
            hasElectricityMeter,
            solarCloudStartDate,
        ],
    );

    const showForecast = shouldShowForecast(
        selectedRes,
        selectedDate,
        provider,
        hasBattery,
    );

    return {
        t,
        toggles: useMemo(
            () => getGraphToggles(type, energy, showForecast),
            [type, energy, showForecast],
        ),
        timezone,
        dispatch,
        fetchData: async (
            date: Date,
            res: HistoricalResolution,
            msg: string,
            onSuccess: UnknownFunction,
            onBeforeFetch?: UnknownFunction,
        ): Promise<void> => {
            if (onBeforeFetch && typeof onBeforeFetch === 'function') {
                onBeforeFetch();
            }

            if (aggregatedData.loading) {
                return;
            }

            try {
                const result = await getAggregatedData(
                    date,
                    res,
                    dispatch,
                    historicalData,
                    energy,
                    { timezone, registrationDate },
                    hasWallbox,
                    hasElectricCar,
                    type,
                );

                onSuccess(result);
            } catch (e) {
                await handleError(e, msg);
            }
        },
        energy,
        isLoading,
        initialDate,
        selectedRes,
        selectedDate,
        hasWallbox,
        hasElectricCar,
        showForecast,
        aggregatedData,
        historicalData,
        registrationDate,
        defaultDateFormat,
        shouldRenderUpdated,
        isTodayWithSmartMeter,
    };
};

export const useInternalRouting = <T>(initialRoute?: T) => {
    const navigate = useNavigate();

    return useCallback(
        (route?: T) => {
            const path = !!route ? route : initialRoute;

            if (!path) {
                return;
            }

            navigate(path);
        },
        [initialRoute, navigate],
    );
};

export const useSmartHomeFilter = () => {
    const dispatch = useDispatch();
    const goTo = useInternalRouting();

    return useCallback(
        (filter: Nullable<string> = null) => {
            if (filter === null) {
                dispatch({
                    type: SmartHomeAction.RESET_FILTER,
                });
            } else {
                dispatch({
                    type: SmartHomeAction.SET_FILTER,
                    payload: [filter],
                });
            }

            goTo(Routes.DEVICES);
        },
        [dispatch, goTo],
    );
};

// DOM Hooks
export const useScrollIntoView = (selector: string): void => {
    useLayoutEffect(() => {
        if (!isMobile) {
            return;
        }

        const element = document.querySelector(selector);

        if (element) {
            element.scrollIntoView({ block: 'center', inline: 'center' });
        }
    });
};

export const useElementWidth = <T extends HTMLElement>(): [
    MutableRefObject<T | null>,
    number,
] => {
    const ref: MutableRefObject<T | null> = useRef<T | null>(null);
    const [width, setWidth] = useState(
        ref.current?.getBoundingClientRect().width || 0,
    );

    const updateWidth = () => {
        setWidth(ref.current?.getBoundingClientRect().width || 0);
    };

    useEffect(() => {
        updateWidth();

        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    return [ref, width];
};

export const useShouldRestrictUser = (condition: boolean) => {
    useEffect(() => {
        document.body.classList[condition ? 'add' : 'remove']('is--restricted');

        return () => {
            if (document.body.classList.contains('is--restricted')) {
                document.body.classList.remove('is--restricted');
            }
        };
    }, [condition]);
};

export const usePvRatedCapacity = () => {
    const pvPeakPower = useAppSelector((state) => state.energy.pvPeakPower);
    const pvGeneration = useAppSelector(
        (state) => state.liveData.telemetries.pvbattery.pvGeneration,
    );
    const pvRatedCapacity = useCallback(
        () => calculatePvRatedCapacity(pvGeneration, pvPeakPower),
        [pvGeneration, pvPeakPower],
    );

    return pvRatedCapacity;
};

export const getRangeUnit = (): RangeUnit => (isGBUser() ? 'mi' : 'km');

export const useRangeUnit = (): RangeUnit => useMemo(() => getRangeUnit(), []);

export const useLogout = () => {
    const goTo = useInternalRouting();
    const dispatch = useDispatch();

    return useCallback(
        (errorCode?: LOGIN_ERROR_CODES, userDeleted: boolean = false) => {
            cleanUpLocalStorage();

            dispatch({
                type: GenericActionTypes.RESET_STATE,
            });

            if (userDeleted) {
                dispatch({
                    type: UserActionTypes.ACCOUNT_DELETE_SUCCESS,
                });

                goTo(Routes.GOODBYE);
            } else {
                dispatch({
                    type: SettingsActionTypes.REQUEST_LOGOUT,
                    payload: errorCode || undefined,
                });
            }
        },
        [dispatch, goTo],
    );
};

export const useSyncRoute = (active?: Routes) => {
    const goTo = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (active && pathname !== active) {
            goTo(active);
        }
    }, [active, goTo, pathname]);
};

export const useIsRestricted = () => {
    useEffect(() => {
        document.body.classList.add('is--restricted');

        return () => {
            document.body.classList.remove('is--restricted');
        };
    }, []);
};

// TODO: Separate different PV onboarding flows property: GridX, Germany with sale and Sweden without sale
export const useFinishSale = () => {
    const sales = useSales();
    const dispatch = useDispatch();
    const { id, steps } = sales[0] || {};
    const installationStep = steps?.find(
        (step: SaleTemplateStepModel) => step.isInstallation,
    );

    return useCallback(
        (installationId: string | boolean) => {
            if (typeof installationId === 'boolean' || !installationStep) {
                return;
            }

            dispatch(
                patchSale(id, {
                    steps: [
                        {
                            id: installationStep.id,
                            status: SaleTemplateStepEditModelStatusEnum.Completed,
                            installationId,
                        },
                    ],
                }),
            );

            dispatch({
                type: UserActionTypes.SET_USER_DATA,
                payload: {
                    hasSales: false,
                },
            });
        },
        [id, dispatch, installationStep],
    );
};

export const useIsOnRoute = (route: Routes) => {
    const { pathname } = useLocation();
    return useMemo(() => pathname === route, [pathname, route]);
};

export const useEmobilityDictionary = () => {
    const { t } = useTranslation();

    const emobilityStatuses: Record<string, string> = useMemo(() => {
        const chargingString = t<string>('Charging');
        const notChargingString = t<string>('Not charging');

        return {
            charging: chargingString,
            stopFailed: chargingString,
            suspendedEV: chargingString,
            stationInUse: chargingString,

            charged: notChargingString,
            available: notChargingString,
            preparing: notChargingString,
            finishing: notChargingString,
            startFailed: notChargingString,
            pending: notChargingString,

            offline: t<string>('Offline'),

            error: t<string>('Error'),
        };
    }, [t]);

    const electricCarStates: Record<string, string> = useMemo(
        () => ({
            offline: t<string>('Your car is'),
            pluggedIn: t<string>('Your car is plugged in and'),
            notPluggedIn: t<string>('Your car is not plugged in and'),
        }),
        [t],
    );

    const errorStatuses: Record<string, string> = useMemo(
        () => ({
            generic: t<string>(
                'An error has occurred, please try again later.',
            ),
            stopFailed: t<string>('Stop failed. Car is charging.'),
            startFailed: t<string>('Charging failed.'),
            suspendedEV: t<string>('EV suspended.'),
        }),
        [t],
    );

    const wallboxStates: Record<string, string> = useMemo(
        () => ({
            offline: t<string>('Your Wallbox is'),
            connected: t<string>('Your car is plugged in and'),
            notConnected: t<string>('Your car is not plugged in and'),
        }),
        [t],
    );

    return {
        wallboxStates,
        errorStatuses,
        electricCarStates,
        emobilityStatuses,
    };
};

export const useHasFlexChargingScope = () =>
    checkForScopes([Scope.EMOBILITY_FLEX_CHARGING_WRITE]);

export const useHasAutopilotChargingWriteScope = () =>
    checkForScopes([Scope.EMOBILITY_AUTOPILOT_WRITE]);

export const useHasAutopilotChargingReadScope = () =>
    checkForScopes([Scope.EMOBILITY_AUTOPILOT_READ]);

export const useHasAutopilotBonusReadScope = () =>
    checkForScopes([Scope.EMOBILITY_BONUS_READ]);

export const useDataAnalysisConsent = (
    dataAnalysisConsent: UserDataConsentModel | undefined,
) => {
    const dispatch = useDispatch();

    const handleStatus = useCallback(
        (status: boolean) => {
            dispatch(setConsentData(status));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataAnalysisConsent, dispatch],
    );

    return handleStatus;
};
